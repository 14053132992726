import { mapMutations } from "vuex";

export default {
    name: "video-card",
    props: {
        video: {
            type: Object,
            default: ()=> {},
        }
    },
    data() {
        return {
        };
    },
    components: {
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        handleVideoPlay(){
          this.showYoutubeVideoModal({id: this.video.video, status: true})
        },
        ...mapMutations({
            showYoutubeVideoModal: "popups/SHOW_YOUTUBE_VIDEO_MODAL",
        })
    },
};
