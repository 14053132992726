import videoCard from "@/modules/video-list/components/video-card/index.vue";
import videoPagination from "@/modules/video-list/components/video-pagination/index.vue";
export default {
    name: "video-list",
    props: {
    },
    data() {
        return {
            payload: {
                page: 1,
                per_page: 9,
            },
            mockArr: [
                {
                    id: 1,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 2,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'XrhSqnZye_M',
                },
                {
                    id: 3,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: '0AhFghYZQ74',
                },
                {
                    id: 4,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 5,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 6,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 7,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 8,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
                {
                    id: 9,
                    slug: 'test-article',
                    title: 'NEW UPDATED FORMULA RENEW Що це?',
                    description: 'Мені буде цікаво почути, якщо хтось скаже, что миє голову "просто водою" Ну, 100%, у кожного є шампунь, а до нього бальзам Ну, 100%, у кожного є шампунь, а до нього бальза Ну, 100%, у кожного є шампунь, а до нього бальза',
                    image: 'https://renew.net.ua/wp-content/uploads/2021/04/%D1%84%D0%BE%D1%82%D0%BE-46-1024x683.jpg',
                    video: 'kLeYYK0rnxI',
                },
            ]
        };
    },
    watch: {
        '$route.query.page': {
            immediate: true,
            handler(newVal){
                if(newVal){
                    this.payload.page = Number(this.$route.query.page)
                }
            }
        },
        payload: {
            deep: true,
            immediate: true,
            handler(newVal){
                if(newVal){
                    console.log('CALL', newVal);
                    //TODO: call api
                }
            }
        }
    },
    components: {
        videoCard,
        videoPagination,
    },
    computed: {
    },
    mounted() {
    },
    methods: {

    },
};
